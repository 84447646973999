import React from "react"
// import ScrollHorizontal from "react-scroll-horizontal"
import ScrollMenu from "react-horizontal-scrolling-menu"
import ArrowSvg from "../utils/svg/pinkArrow.svg"
import { BrushAnimation } from "./main-page-scroll"
// import Brush from './Brush/index'
export default class ExpertisePeek extends React.Component {
  constructor(props) {
    super(props)
    this.pages = ["Start", "Distributed Teams"]
  }
  render() {
    const ScrollRenderer = (index, item) => {
      switch (index) {
        case 0: {
          return (
            <div
              className="main-page-first-tile"
              style={{ paddingLeft: "0px" }}
              key={index}
            >
              <div className="main-page-first-wrapper">
                <h1>
                  ProSource builds and manages&nbsp;
                  <BrushAnimation title={"amazing"} />
                  distributed teams for clients around the world.
                </h1>
                <div className="main-page-first-foot-wrapper">
                  <h1>
                    About Us
                    <img
                      src={ArrowSvg}
                      alt="arrow"
                      className="sub-title-arrow"
                    ></img>
                  </h1>
                  <h4>Scroll to see more</h4>
                </div>
              </div>
            </div>
          )
        }
        default: {
          return (
            <div className="expertise-body">
              <div className="expertise-label">
                <div className="expertise-title">{item}</div>
                <div className="expertise-details">
                  The next evolution of outsourcing
                </div>
                <div className="expertise-view-more">
                  View More{" "}
                  <img
                    src={ArrowSvg}
                    alt="arrow"
                    className="view-more-arrow"
                  ></img>
                </div>
              </div>
            </div>
          )
        }
      }
    }
    const pageItems = this.pages.map((item, i) => {
      return ScrollRenderer(i, item)
    })

    return (
      <ScrollMenu
        data={pageItems}
        alignCenter={false}
        selected={false}
        dragging={false}
        clickWhenDrag={false}
        alignOnResize={false}
        wheel={false}
        itemStyle={{
          borderRight: "1px solid rgba(89, 89, 89, 0.1)",
          display: "inline-block",
          position: "relative",
          height: "50%",
          minWidth: "60rem",
          whiteSpace: "normal",
          overflow: "hidden",
          boxSizing: "border-box",
          verticalAlign: "bottom",
          outline: "0px",
        }}
        innerWrapperStyle={{
          height: "90%",

          whiteSpace: "pre-wrap",
        }}
        wrapperStyle={{
          position: "relative",
          top: "0px",
          bottom: "20px",
          right: "0px",
          left: "10%",
          overflow: "visible",
          marginBottom: "-17px",
        }}
        wrapperClass={"wrapper-class-horizontal"}
      ></ScrollMenu>
    )
  }
}
