// Used at V2

import React from "react"
import { Link, navigate } from "gatsby"
import Collapse from "react-bootstrap/Collapse"
import * as Scroll from "react-scroll"
import VisibilitySensor from "react-visibility-sensor"

import ArrowSVG from "../../utils/svg/arrow-2.svg"
import ArrowUp from "../../utils/svg/arrow-up.svg"
import ArrowDown from "../../utils/svg/arrow-down.svg"
import PinkArrowSVG from "../../utils/svg/pinkArrow.svg"

import ImageLinks from "../image-links/image-links"
import Brushes from "../Brush/brushes-path"

import { IoIosBook } from "react-icons/io"

let scroll = Scroll.animateScroll

// Partials

class SectionRow extends React.Component {
  render() {
    const { sectionName, sectionLabelRight } = this.props
    return (
      <div className="section-row">
        <span> {sectionName} </span>
        <span className="pr-align-right">{sectionLabelRight}</span>
      </div>
    )
  }
}

export class Section extends React.Component {
  render() {
    const {
      sectionName,
      sectionLabelRight = "",
      children,
      sectionClassName = "section-div",
    } = this.props

    return (
      <div className={sectionClassName}>
        <SectionRow
          sectionName={sectionName}
          sectionLabelRight={sectionLabelRight}
        />
        {children}
      </div>
    )
  }
}

export class SubSection extends React.Component {
  render() {
    const { children, style, className } = this.props

    return (
      <div
        className={`section-body${className ? " " + className : ""}`}
        style={style}
      >
        {children}
      </div>
    )
  }
}

export class StatCardList extends React.Component {
  render() {
    const { children, className = "", style } = this.props

    return (
      <ul
        className={`section-stats${className !== "" ? " " + className : ""}`}
        style={style}
      >
        {children}
      </ul>
    )
  }
}

export class ImageListItem extends React.Component {
  render() {
    const { children, className } = this.props
    return (
      <div className={`image-item ${className ? className : ""}`}>
        {children}
      </div>
    )
  }
}

export class ImageCard extends React.Component {
  render() {
    const { title, text, image } = this.props

    return (
      <div className={"image-card"}>
        <div className={"image-holder"}>
          <div className={"title-holder"}>
            <span className={"title-text"}>{title}</span>
            <br />
            <br />
            <span className={"sub-title-text"}>{text}</span>
            <br />
            <img alt="key" class="image-style" src={ImageLinks[image]} />
          </div>
        </div>
      </div>
    )
  }
}
export class ImagesList extends React.Component {
  render() {
    const { children } = this.props
    return <div className={"image-group"}>{children}</div>
  }
}
export class StatCard extends React.Component {
  render() {
    const { number, emphasizedDesc = "", normalDescription } = this.props

    return (
      <li className="section-stats-card">
        <div className="section-stats-card-number">{number}</div>
        <p className="section-stats-card-description">
          <span className="section-stats-card-description-em">
            {emphasizedDesc}
          </span>{" "}
          &mdash; {normalDescription}
        </p>
      </li>
    )
  }
}

export class PLink extends React.Component {
  constructor(props) {
    super(props)
    this.handleOnClick = this.handleOnClick.bind(this)
    this.cleanLink = this.cleanLink.bind(this)
  }

  cleanLink = (link) => {
    if (link) {
      return link.replace(/\//g, "")
    } else {
      return link
    }
  }

  handleOnClick = (event) => {
    event.preventDefault()
    const { headerActive, ignoreScrollTop = false, location } = this.props
    const navigateLinkText = event.currentTarget.dataset.text,
      navigateLink = event.currentTarget.getAttribute("href"),
      SiteWrapper = document.getElementById("site-wrapper")

    if (navigateLink) {
      if (ignoreScrollTop) {
        if (SiteWrapper) SiteWrapper.classList.add("animated", "fadeOut")
        setTimeout(() => {
          navigate(navigateLink)
        }, 1000)
      } else {
        if (headerActive === navigateLinkText) {
          scroll.scrollToTop()
        } else {
          let cont = true

          if (location && navigateLink !== undefined) {
            if (
              this.cleanLink(navigateLink) === this.cleanLink(location.pathname)
            ) {
              cont = false
            }
          }

          if (cont) {
            if (SiteWrapper) SiteWrapper.classList.add("animated", "fadeOut")

            setTimeout(() => {
              navigate(navigateLink)
            }, 1000)
          }
        }
      }
    }
  }

  render() {
    const {
      to,
      children,
      className = "p-link",
      style,
      id,
      onClickFadeOut = false,
      onClick,
      dataText = null,
    } = this.props
    if (onClickFadeOut) {
      return (
        <Link
          to={to}
          className={className}
          style={style}
          id={id}
          onClick={this.handleOnClick}
          data-text={dataText}
        >
          {children}
        </Link>
      )
    } else {
      // normal link
      return (
        <Link
          to={to}
          className={className}
          style={style}
          id={id}
          onClick={onClick}
        >
          {children}
        </Link>
      )
    }
  }
}

export class PLinkLocal extends React.Component {
  render() {
    const {
      children,
      className = "p-link",
      style,
      id,
      onClick,
      dataText,
    } = this.props
    return (
      <Link
        to={"#"}
        className={className}
        style={style}
        id={id}
        onClick={onClick}
        data-text={dataText}
      >
        {" "}
        {children}{" "}
      </Link>
    )
  }
}
export class HeroTitleDiv extends React.Component {
  render() {
    const { children, className } = this.props

    return (
      <div className={`hero-title-div${className ? " " + className : ""}`}>
        <h1 className="hero-title">{children}</h1>
      </div>
    )
  }
}

export class HeroSubtitle extends React.Component {
  render() {
    const { children, className } = this.props
    return (
      <div className={`sub-hero-title${className ? " " + className : ""}`}>
        {children}
      </div>
    )
  }
}

export class BaseDiv extends React.Component {
  render() {
    const { children, className } = this.props

    return <div className={className}>{children}</div>
  }
}

export const Video = ({
  videoSrcURL,
  videoTitle,
  width,
  height,
  ref,
  className,
  style,
  loop = true,
}) => (
  <div className="video" style={style}>
    <video
      width={width}
      height={height}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
      autoPlay
      loop={loop}
      ref={ref}
      className={className}
    >
      <source src={videoSrcURL} type="video/mp4" />
    </video>
  </div>
)

export class AccordionList extends React.Component {
  render() {
    const { children } = this.props
    return <div className="accordion-list">{children}</div>
  }
}

export class AccordionListItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
    }

    this.HandleAccordionListItemClick =
      this.HandleAccordionListItemClick.bind(this)
  }

  HandleAccordionListItemClick = (event) => {
    const parentAccordion = event.currentTarget.parentElement.parentElement,
      { open } = this.state
    let currentActiveAccordionListItems =
      parentAccordion.getElementsByClassName(
        "accordion-list-item--active"
      ).length

    if (open) {
      // will close
      currentActiveAccordionListItems--
    } else {
      currentActiveAccordionListItems++
    }

    if (currentActiveAccordionListItems > 0) {
      parentAccordion.classList.add("accordion-list--has-active")
    } else {
      parentAccordion.classList.remove("accordion-list--has-active")
    }

    this.setState({ open: !open })
  }

  render() {
    const { children, toggleHtml } = this.props,
      { open } = this.state

    return (
      <div
        className={`accordion-list-item${
          open ? " accordion-list-item--active" : ""
        }`}
      >
        <div
          className="accordion-list-item-toggle"
          onClick={this.HandleAccordionListItemClick}
        >
          {toggleHtml}
          <div className="accordion-list-item-toggle-arrow">
            <img alt="" src={open ? ArrowUp : ArrowDown} />
          </div>
        </div>
        <Collapse in={open} className={`animated ${open ? "fadeIn" : ""}`}>
          {children}
        </Collapse>
      </div>
    )
  }
}

export class ArrowList extends React.Component {
  render() {
    const { children, className = "" } = this.props
    return (
      <ul className={`arrow-list${className !== "" ? " " + className : ""}`}>
        {children}
      </ul>
    )
  }
}

export class ArrowListItem extends React.Component {
  render() {
    const { children, className = "" } = this.props
    return (
      <li
        className={`arrow-list-item${className !== "" ? " " + className : ""}`}
      >
        {" "}
        <img alt="" className="arrow-list-item-arrow" src={ArrowSVG} />{" "}
        {children}
      </li>
    )
  }
}

export class BoxList extends React.Component {
  render() {
    const { children, className = "" } = this.props
    return (
      <div className={`box-list${className !== "" ? " " + className : ""}`}>
        {children}
      </div>
    )
  }
}

export class BoxListItem extends React.Component {
  render() {
    const { children, className = "" } = this.props
    return (
      <div
        className={`box-list-item${className !== "" ? " " + className : ""}`}
      >
        <div className="box-list-item-content">{children}</div>
      </div>
    )
  }
}
export class BrushAnimation extends React.Component {
  constructor(props) {
    super(props)
    this.strokeTitle = React.createRef()
  }

  render() {
    const {
      brushClassName = "",
      spanClassName = "long-brush-animation-text",
      svgClassName = "long-brush-animation",
      brushType = "Brush3",
    } = this.props

    const onVisibleChange = (isVisible) => {
      try {
        if (isVisible) {
          this.strokeTitle.current.classList.add("stroke-animation")
          this.strokeTitle.current.classList.remove("custom-hidden")
        } else {
          this.strokeTitle.current.classList.remove("stroke-animation")
          this.strokeTitle.current.classList.add("custom-hidden")
        }
      } catch (err) {
        console.log(err)
      }
    }

    const BrushColor = `rgb(234,23,139)`
    let SelectedBrush,
      SelectedBrushClassName = ""

    switch (brushType) {
      case "Brush3":
        SelectedBrush = Brushes.Brush3
        SelectedBrushClassName = `psg-brush--3`
        break
      case "Brush4":
        SelectedBrush = Brushes.Brush4
        SelectedBrushClassName = `psg-brush--4`
        break
      case "Brush4B":
        SelectedBrush = Brushes.Brush4
        SelectedBrushClassName = `psg-brush--4`
        SelectedBrush.ViewBox = "0 -80 100 100"
        break
      case "Brush5":
        SelectedBrush = Brushes.Brush5
        SelectedBrushClassName = `psg-brush--5`
        break
      default:
        SelectedBrush = Brushes.Brush3
        SelectedBrushClassName = `psg-brush--3`
        break
    }

    if (this.props.viewBox) {
      SelectedBrush.ViewBox = this.props.viewBox
    }

    return (
      <VisibilitySensor onChange={onVisibleChange}>
        <span
          className={`${SelectedBrushClassName}${
            brushClassName !== "" ? " " + brushClassName : ""
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`section-page-brush ${svgClassName}`}
            viewBox={SelectedBrush.ViewBox}
          >
            <SelectedBrush.Def />
            <path
              ref={this.strokeTitle}
              clipPath={SelectedBrush.ClipPath}
              d={SelectedBrush.Path}
              strokeWidth={SelectedBrush.StrokeWidth}
              fill="none"
              stroke={BrushColor}
            ></path>
          </svg>
          <span className={spanClassName}>{this.props.title}</span>
        </span>
      </VisibilitySensor>
    )
  }
}

export class FooterCard extends React.Component {
  render() {
    const {
      cardTitle = "",
      children,
      className = "",
      style,
      whiteSpaceSpan = "pre-wrap",
    } = this.props

    return (
      <div
        className={`footer-card${className ? " " + className : ""}`}
        style={style}
      >
        <span className="sp-bold-text">{cardTitle}</span>
        <span style={{ whiteSpace: whiteSpaceSpan }}>{children}</span>
      </div>
    )
  }
}

export class CardList extends React.Component {
  render() {
    const { className = "", children } = this.props
    return (
      <div className={`card-list${className ? " " + className : ""}`}>
        {children}
      </div>
    )
  }
}

export class CardListItem extends React.Component {
  render() {
    const { cardTitle = "", className = "", style, children } = this.props

    return (
      <div
        className={`card-list-item${className ? " " + className : ""}`}
        style={style}
      >
        <div className="card-list-item--text">{cardTitle}</div>
        <div className="card-list-item--body">{children}</div>
      </div>
    )
  }
}

export class BlogList extends React.Component {
  constructor(props) {
    super(props)

    const PER_PAGE = 6,
      NUMBER_OF_PAGES = Math.ceil(this.props.content.length / PER_PAGE)

    this.state = {
      currentPage: 1,
      postsPerPage: PER_PAGE,
      numberOfPages: NUMBER_OF_PAGES,
      startIndex: 0,
    }

    this.nextPage = this.nextPage.bind(this)
    this.prevPage = this.prevPage.bind(this)
    this.navigationAnimation = this.navigationAnimation.bind(this)
  }

  navigationAnimation() {
    Scroll.animateScroll.scrollToTop()
  }

  nextPage = (event) => {
    // Older
    event.preventDefault()

    const { currentPage, startIndex, postsPerPage } = this.state

    this.refs.blogListDiv.classList.remove("fadeIn")
    this.refs.blogListDiv.classList.add("animated", "fadeOut")

    setTimeout(() => {
      this.refs.blogListDiv.classList.add("fadeIn")
      this.refs.blogListDiv.classList.remove("fadeOut")

      this.setState({
        currentPage: currentPage + 1,
        startIndex: startIndex + postsPerPage,
      })
    }, 500)
  }

  prevPage = (event) => {
    // Newer
    event.preventDefault()

    const { currentPage, startIndex, postsPerPage } = this.state

    const newStartIndex = startIndex - postsPerPage
    this.refs.blogListDiv.classList.remove("fadeIn")
    this.refs.blogListDiv.classList.add("animated", "fadeOut")

    setTimeout(() => {
      this.refs.blogListDiv.classList.add("fadeIn")
      this.refs.blogListDiv.classList.remove("fadeOut")
      this.setState({
        currentPage: currentPage - 1,
        startIndex: newStartIndex < 0 ? 0 : newStartIndex,
      })
    }, 500)
  }

  render() {
    const { className = "", showList, content } = this.props,
      { currentPage, numberOfPages, startIndex, postsPerPage } = this.state
    const endIndex = startIndex + postsPerPage - 1,
      showNext = currentPage < numberOfPages,
      showPrev = currentPage > 1

    return (
      <Section
        sectionName={`${currentPage} of ${numberOfPages} pages`}
        sectionLabelRight={`01`}
        sectionClassName="insights-homepage-section"
      >
        <div
          className={`blog-list${className ? " " + className : ""}`}
          ref={`blogListDiv`}
        >
          {showList ? (
            content.map((item, index) => {
              let display = false

              if (index >= startIndex && index <= endIndex) {
                display = true
              }

              return display ? <BlogListItem item={item} /> : <EmptyComponent />
            })
          ) : (
            <p>No posts to show.</p>
          )}
        </div>
        <div className="blog-list-pagination">
          <div className="blog-list-pagination-item blog-list-pagination-item--prev">
            {showPrev ? (
              <PLinkLocal onClick={this.prevPage}>Newer</PLinkLocal>
            ) : (
              <EmptyComponent />
            )}
          </div>
          <div className="blog-list-pagination-item blog-list-pagination-item--next">
            {showNext ? (
              <PLinkLocal onClick={this.nextPage}>Older</PLinkLocal>
            ) : (
              <EmptyComponent />
            )}
          </div>
        </div>
      </Section>
    )
  }
}

export class BlogListItem extends React.Component {
  render() {
    const { className = "", style } = this.props

    const { thumbnail, title, description, author, date, minutes } =
      this.props.item
    let link = `insight${this.props.item.link}`
    const tlink = link === "/" ? "/" : "/" + link
    return (
      <PLink
        className={`blog-list-item${className ? " " + className : ""}`}
        style={style}
        to={tlink}
        onClickFadeOut={true}
        ignoreScrollTop={true}
      >
        <div className={`blog-list-item--thumbnail`}>
          {thumbnail.childImageSharp ? (
            <img
              src={thumbnail.childImageSharp.fluid.src}
              alt="blog-list-item-thumbnail"
            />
          ) : (
            <img src={"/" + thumbnail} alt="blog-list-item-thumbnail" />
          )}
        </div>
        <div className={`blog-list-item--title`}>{title}</div>
        <div className={`blog-list-item--description`}>{description}</div>
        <div className={`blog-list-item--author`}>
          By {author} - {date}
        </div>
        <div className={`blog-list-item--min`}>
          <IoIosBook /> {minutes} min read
        </div>
      </PLink>
    )
  }
}

export class BigTextList extends React.Component {
  render() {
    const { children } = this.props
    return <div className="big-text-list">{children}</div>
  }
}

export class BigTextListItem extends React.Component {
  render() {
    const { children, link } = this.props,
      item = (
        <div className="big-text-list-item">
          <div className="big-text-list-item--text">{children}</div>
        </div>
      )

    if (link) {
      return (
        <PLink to={link} onClickFadeOut={true} ignoreScrollTop={true}>
          {item}
        </PLink>
      )
    } else {
      return item
    }
  }
}

export class RuleDivList extends React.Component {
  render() {
    const { children, className } = this.props

    return (
      <div
        className={`prosource-rule-div-list${className ? " " + className : ""}`}
      >
        {children}
      </div>
    )
  }
}

export class RuleDiv extends React.Component {
  render() {
    const { title, number = "00", children } = this.props

    return (
      <div className="prosource-rule-div">
        <div className="prosource-rule-div--number">{number}</div>
        <div className="prosource-rule-div--title">{title}</div>
        <div className="prosource-rule-div--content">
          <p>{children}</p>
        </div>
      </div>
    )
  }
}

export class PinkArrow extends React.Component {
  render() {
    const { className } = this.props

    return (
      <img
        src={PinkArrowSVG}
        alt="arrow"
        className={`${className ? " " + className : ""}`}
      />
    )
  }
}

export class PLinkWithArrow extends React.Component {
  render() {
    const {
      to,
      className,
      children,
      onClickFadeOut,
      ignoreScrollTop,
      pinkArrowClassName,
      target,
      externalLink = false,
    } = this.props

    const externalLinkComponent = (
      <>
        <a
          href={to}
          target={target}
          className={`p-link${className ? " " + className : ""}`}
        >
          {children} <PinkArrow className={pinkArrowClassName} />
        </a>
      </>
    )
    const tto = "/" + to
    return externalLink ? (
      externalLinkComponent
    ) : (
      <PLink
        to={tto}
        className={`p-link${className ? " " + className : ""}`}
        onClickFadeOut={onClickFadeOut}
        ignoreScrollTop={ignoreScrollTop}
      >
        {children}
        <PinkArrow className={pinkArrowClassName} />
      </PLink>
    )
  }
}

export class DivSeparator extends React.Component {
  render() {
    return <div className="div-separator"></div>
  }
}

export class EmptyComponent extends React.Component {
  render() {
    return <></>
  }
}
