// import bg1 from "../../content/assets/expertisecovers/dotnet.jpg"
import bg0 from "../../content/assets/expertisecovers/renders/Distributed_Teams.jpg"
import bg1 from "../../content/assets/expertisecovers/renders/Net.jpg"
// import bg2 from "../../content/assets/image1.jpg"
import bg2 from "../../content/assets/expertisecovers/renders/AWS.jpg"
import bg3 from "../../content/assets/expertisecovers/renders/NodeJS.jpg"
import bg4 from "../../content/assets/expertisecovers/renders/Angular.jpg"

import bg5 from "../../content/assets/expertisecovers/renders/DevOps.jpg"
// import bg6 from "../../content/assets/expertisecovers/ios-android.jpg"
import bg6 from "../../content/assets/expertisecovers/renders/Android_iOS.jpg"
import bg7 from "../../content/assets/expertisecovers/renders/Java.jpg"
import bg8 from "../../content/assets/expertisecovers/renders/PHP_Wordpress.jpg"
import bg9 from "../../content/assets/expertisecovers/renders/React.jpg"
import bg10 from "../../content/assets/expertisecovers/renders/Salesforce.jpg"
import bg11 from "../../content/assets/expertisecovers/renders/csharp.jpg"

import mbg0 from "../../content/assets/expertisecovers/mobile-view/dteams.jpg"
import mbg1 from "../../content/assets/expertisecovers/mobile-view/Net-Iphone-Still.jpg"
import mbg2 from "../../content/assets/expertisecovers/mobile-view/AWS-Iphone-Still.jpg"
import mbg3 from "../../content/assets/expertisecovers/mobile-view/NodeJS-Iphone-Still.jpg"

import mbg4 from "../../content/assets/expertisecovers/mobile-view/Angular-Iphone-Still.jpg"

import mbg5 from "../../content/assets/expertisecovers/mobile-view/DevOps-Iphone-Still.jpg"
import mbg6 from "../../content/assets/expertisecovers/mobile-view/Android_iOS-Still.jpg"
import mbg7 from "../../content/assets/expertisecovers/mobile-view/Java-Iphone-Still.jpg"
import mbg8 from "../../content/assets/expertisecovers/mobile-view/PHP_Wordpress-Still.jpg"
import mbg9 from "../../content/assets/expertisecovers/mobile-view/React-Iphone-Still.jpg"
import mbg10 from "../../content/assets/expertisecovers/mobile-view/Salesforce-Iphone-Still.jpg"
import mbg11 from "../../content/assets/expertisecovers/mobile-view/csharp.jpg"

import nodeJSBanner from "../../content/assets/expertisecovers/banners/bgnode.jpg"
import banner1 from "../../content/assets/banner1.jpg"
import banner2 from "../../content/assets/banner2.jpg"
import banner3 from "../../content/assets/banner3.jpg"

import banner4 from "../../content/assets/banner4.png"
import banner from "../../content/assets/banner.jpg"

import dotnetsmparallax from "../../content/assets/expertisecovers/small-parallax/dotnet.png"
import angularsmparallax from "../../content/assets/expertisecovers/small-parallax/angular.jpg"
import distriteamparallax from "../../content/assets/expertisecovers/small-parallax/distriteams.jpg"
import salesforceparallax from "../../content/assets/expertisecovers/small-parallax/salesforce.jpg"
import devopsparallax from "../../content/assets/expertisecovers/small-parallax/devops.jpg"

import iossmp from "../../content/assets/expertisecovers/small-parallax/android-ios.jpg"
import awssmp from "../../content/assets/expertisecovers/small-parallax/aws.jpeg"
import csharpsmp from "../../content/assets/expertisecovers/small-parallax/csharp.jpg"
import javasmp from "../../content/assets/expertisecovers/small-parallax/java.jpg"
import reactparallax from "../../content/assets/expertisecovers/small-parallax/react.png"
import phpparallax from "../../content/assets/expertisecovers/small-parallax/php.jpg"

const imports = {
  bg0: bg0,
  bg1: bg1,
  bg2: bg2,
  bg3: bg3,
  bg4: bg4,
  bg5: bg5,
  bg6: bg6,
  bg7: bg7,
  bg8: bg8,
  bg9: bg9,
  bg10: bg10,
  bg11: bg11,
  mbg0: mbg0,
  mbg1: mbg1,
  mbg2: mbg2,
  mbg3: mbg3,
  mbg4: mbg4,
  mbg5: mbg5,
  mbg6: mbg6,
  mbg7: mbg7,
  mbg8: mbg8,
  mbg9: mbg9,
  mbg10: mbg10,
  mbg11: mbg11,
  banner1: banner1,
  banner2: banner2,
  banner3: banner3,
  banner4: banner4,
  banner: banner,
  nodeJSBanner: nodeJSBanner,
  dotnetsmparallax: dotnetsmparallax,
  angularsmparallax: angularsmparallax,
  distriteamparallax: distriteamparallax,
  salesforceparallax: salesforceparallax,
  devopsparallax: devopsparallax,
  iossmp: iossmp,
  awssmp: awssmp,
  csharpsmp: csharpsmp,
  javasmp: javasmp,
  reactparallax: reactparallax,
  phpparallax: phpparallax,
}

export default imports
