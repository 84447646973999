// import bg1 from "../../content/assets/expertisecovers/dotnet.jpg"
import image1 from "../../../content/assets/approach/image1.jpg"
import image2 from "../../../content/assets/approach/image2.jpg"
import image3 from "../../../content/assets/approach/image3.jpg"
import image4 from "../../../content/assets/approach/image4.jpg"
import image5 from "../../../content/assets/approach/image5.jpg"
import image6 from "../../../content/assets/approach/image6.jpg"
import image7 from "../../../content/assets/approach/image7.jpg"

const imports = {
  image1: image1,
  image2: image2,
  image3: image3,
  image4: image4,
  image5: image5,
  image6: image6,
  image7: image7
}

export default imports
